import React from "react";
import { IntlShape, useIntl } from "gatsby-plugin-intl";
import { graphql } from "gatsby";
import { ImageDataLike } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import Seo from "../components/Seo";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { PrjTitle } from "../components/Project/PrjTitle";
import PrjStack from "../components/Project/PrjStack";
import { ProjectImage } from "../components/Projects/ProjectImage";
import { PrjFeature } from "../components/Project/PrjFeature";
import ContactSection from "../components/ContactSection";
import PrjLinks from "../components/Project/PrjLinks";
import { MDXProvider } from "@mdx-js/react";
import { PrjList } from "../components/Project/PrjList";
import PrjTech from "../components/Project/PrjTech";

type ProjectDetailsDataType = {
  data: {
    mdx: {
      frontmatter: {
        title: string;
        tech: string[];
        techFE: string[];
        techBE: string[];
        features: string[];
        appLink: string;
        githubLink: string;
        description: string;
        type: string;
        image: {
          childImageSharp: ImageDataLike;
        };
        images: {
          childImageSharp: ImageDataLike;
        };
        imagesRow: { childImageSharp: ImageDataLike }[];
        gifs: {
          publicURL: string;
        };
      };
      body: string;
    };
  };
};

// FIXME: split to multiple componennts for better readability

const ProjectDetails: React.FC<ProjectDetailsDataType> = ({
  data: {
    mdx: { frontmatter: project, body },
  },
}) => {
  const intl: IntlShape = useIntl();

  return (
    <>
      <Seo title={`${project.title} - Kubahrom`} />
      <Layout intl={intl}>
        <div className="prj">
          <div className="prj__heading">
            <h1 className="prj__heading--title">{project.title}</h1>
            <p className="prj__heading--subtitle">
              {project.type[0].toUpperCase() + project.type.slice(1)}
            </p>
          </div>
          <div className="prj__body">
            <div className="prj__content-header">
              <div>
                <p className="prj__description">{project.description}</p>
                <PrjLinks
                  githubLink={project.githubLink}
                  appLink={project.appLink}
                />
                {!project.techFE && !project.techFE && (
                  <PrjTech tech={project.tech} />
                )}
              </div>
              <div className="prj__image">
                <ProjectImage
                  image={project.image.childImageSharp}
                  alt={project.title}
                />
              </div>
            </div>
            {project.techFE && project.techFE && (
              <PrjStack techFE={project.techFE} techBE={project.techBE} />
            )}
            {project.features && (
              <div>
                <PrjTitle>Features</PrjTitle>
                <PrjList feature>
                  {project.features.map((feature, index) => (
                    <PrjFeature key={index} feature={feature} />
                  ))}
                </PrjList>
              </div>
            )}
            <div className="prj__mdx">
              <MDXProvider components={{}}>
                <MDXRenderer
                  gifs={project.gifs}
                  images={project.images}
                  imagesRow={project.imagesRow}
                >
                  {body}
                </MDXRenderer>
              </MDXProvider>
            </div>
          </div>
        </div>
        <ContactSection intl={intl} />
      </Layout>
    </>
  );
};

export default ProjectDetails;

export const query = graphql`
  query GetProject($slug: String) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        tech
        techFE
        techBE
        features
        description
        appLink
        githubLink
        type
        image {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, AVIF, WEBP])
          }
        }
        images {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, AVIF, WEBP])
          }
        }
        imagesRow {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: AUTO)
          }
        }
        gifs {
          publicURL
        }
      }
      body
    }
  }
`;
