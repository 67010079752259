import React from "react";
import Tech from "../Projects/Tech";
import { PrjTitle } from "./PrjTitle";

type prjTechType = {
  tech: string[];
};

const PrjTech = ({ tech }: prjTechType) => {
  return (
    <div className="prj__tech-stack">
      <PrjTitle>Tech stack</PrjTitle>
      <div className="prj__tech-wrapper">
        {tech.map((t, index) => (
          <Tech key={index} tech={t} />
        ))}
      </div>
    </div>
  );
};

export default PrjTech;
