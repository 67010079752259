import React from "react";

import { PrjTitle } from "./PrjTitle";

type prjLinksType = {
  appLink: string;
  githubLink: string;
};

const PrjLinks = ({ appLink, githubLink }: prjLinksType) => {
  return (
    <div className="prj__links">
      <div className="prj__link">
        <div className="prj__link--title">
          <PrjTitle>Available on</PrjTitle>
          <svg
            stroke="currentColor"
            fill="none"
            strokeWidth="2"
            viewBox="0 0 24 24"
            strokeLinecap="round"
            strokeLinejoin="round"
            height="24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
            className="prj__link--icon"
          >
            <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
            <polyline points="15 3 21 3 21 9"></polyline>
            <line x1="10" y1="14" x2="21" y2="3"></line>
          </svg>
        </div>
        <a
          href={appLink}
          target="_blank"
          aria-label="Link to project"
          className="prj__link--link"
        >
          {appLink}
        </a>
      </div>
      {githubLink && (
        <div className="prj__link">
          <div className="prj__link--title">
            <PrjTitle>Github</PrjTitle>
            <svg
              stroke="currentColor"
              fill="none"
              strokeWidth="2"
              viewBox="0 0 24 24"
              strokeLinecap="round"
              strokeLinejoin="round"
              height="24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
              className="prj__link--icon"
            >
              <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path>
            </svg>
          </div>
          <a
            href={githubLink}
            target="_blank"
            aria-label="Link to project"
            className="prj__link--link"
          >
            {githubLink}
          </a>
        </div>
      )}
    </div>
  );
};

export default PrjLinks;
