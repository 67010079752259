import React from 'react';

type TechProps = {
  tech: string;
};

const Tech: React.FC<TechProps> = ({ tech }) => {
  return <span className="project__tech">{tech}</span>;
};

export default Tech;
