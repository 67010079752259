import React from "react";
import Tech from "../Projects/Tech";
import { PrjTitle } from "./PrjTitle";

type prjStackType = {
  techFE: string[];
  techBE: string[];
};

const PrjStack = ({ techFE, techBE }: prjStackType) => {
  return (
    <div className="prj__tech-stack-wrapper">
      {techBE && (
        <div className="prj__tech-stack">
          <PrjTitle>Frontend</PrjTitle>
          <div className="prj__tech-wrapper">
            {techFE.map((t, index) => (
              <Tech key={index} tech={t} />
            ))}
          </div>
        </div>
      )}
      {techBE && (
        <div className="prj__tech-stack">
          <PrjTitle>Backend</PrjTitle>
          <div className="prj__tech-wrapper">
            {techBE.map((t, index) => (
              <Tech key={index} tech={t} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default PrjStack;
